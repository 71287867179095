// eslint-disable-next-line
import React from 'react';
import { mdx } from 'mdx.macro';

const Content = mdx`
## להלן הצהרה משפטית חשובה: 

1. אתר זה נבנה בהתנדבות ומתוך רצון לסייע לאזרחים לממש את זכות היסוד החוקתית שלהם להפגין למען המשטר הדמוקרטי ושלטון החוק. 

2. אתר זה נועד לסייע לאזרחים להפגין תוך שמירה על החוק ועל תקנת איסור הפגנה מעבר למתחם קילומטר אחד מהבית. 

3. בוני האתר ומפעיליו אינם אחראים משפטית לשום נזק או פגיעה שייגרמו למפגין שאינו שומר על הכללים ועל הוראות החוק והתקנות. 

4. אנו קוראים לכל המפגינים והמפגינות לפעול אך ורק בהתאם לחוק ולתקנות ולכללי הזהירות הרפואית.
`;

export default Content;
