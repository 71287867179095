// eslint-disable-next-line
import React from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import Helmet from 'react-helmet';
import { mdx } from 'mdx.macro';

const Content = mdx`
<Helmet>
  <title>אודות הפרוייקט</title>
</Helmet>

## על הפרוייקט

האתר "קילומטר אחד" הוקם על מנת לעזור לאזרחים בכל רחבי הארץ למצוא הפגנות ברדיוס קילומטר מהבית, בזמן הגבלת ההפגנות שחלה בתחילת אוקטובר 2020.

בעקבות ההגבלה המחאה הגיעה למימדים חדשים: אלפי מפגינים ומפגינות יצאו לרחובות, חלקן הגדול בפעם הראשונה בכיכר הקרובה לביתן.

התגובות שקיבלנו על האתר נתנו לנו הרגשה שיש צורך במשהו מעבר לפונקציות הבסיסיות של מציאת הפגנות קרובות לבית.  
הרגשנו שיש צורך ביצירת נקודת מפגש וירטואלית של כל המחאות בארץ. ביצירת רשת שתעזור ליצירת קשרים, לאיחוד כוחות ולשיתוף ידע. רשת שתעודד את מי שעוד מהסס לצאת לרחוב - לצעוד בראש מורם אל מוקד המחאה הקרוב.

יש לנו עוד הרבה תוכניות איך לשפר את האתר ואת החוויה המשולבת של הפגנה אמיתית עם נוכחות דיגיטלית - אנחנו זזות ביחד, נשארות כל הזמן בתנועה ומגיבות למה שקורה בשטח.  
צוות האתר נמצא בהיכון לבקשות ולצרכים שלכן. 

האתר ללא מטרות רווח, ומבוסס על מתנדבים ותרומות.  
קוד האתר [פתוח ומפורסם לציבור](https://github.com/guytepper/1km.co.il/) ועלויות התחזוקה והרווחים מהתרומות יהיו חשופים לכל בהמשך.  
ראוי לציין שאנחנו בכוונה נמנעים להשתייך לארגונים השונים שמארגנים את ההפגנות אלא רוצים לשתף פעולה עם כולם ולתת לכולם במה לארגון הפגנות.  


לפרוייקט יש תאריך סיום אחד - היום שבו בלפור יתנקה ממושחתים - ובשביל שנוכל לקדם את הפרוייקט נצטרך שהוא יגיע לכמה שיותר אנשים. הפיצו את האתר למעגלים החברתיים שלכם, ועקבו אחרינו ברשתות החברתיות (אנחנו ב[פייסבוק](https://www.facebook.com/1km.co.il), [טוויטר](https://twitter.com/1kmcoil) ו[אינסטגרם](https://www.instagram.com/1km.co.il/)).
 
כמו כן, נהיה אסירי תודה על כל תרומה - בקרו ב<Link to="/donate">עמוד התרומה</Link> לצפייה באפשרויות העברת התשלום השונות.

<div style={{ textAlign: 'center' }}>
<p>נפגש ברחובות! <br/>
צוות קילומטר אחד</p>
</div>
`;

export default Content;
