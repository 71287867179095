// eslint-disable-next-line
import React from 'react';
// eslint-disable-next-line
import { Link } from 'react-router-dom';
import { mdx } from 'mdx.macro';

const Content = mdx`
# אופס...
 עמוד זה לא קיים  
<Link to="/">בחזרה לעמוד הראשי</Link>
`;

export default Content;
